import React, {Component} from 'react';

import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';

import DataOnlyScreen from './screens/demo';
import DemoScreen from './screens/demo-revamp';

export default class AppRouter extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/draft" exact component={DataOnlyScreen} />
          <Route path="/:modelId" component={DemoScreen} />
          <Redirect to="/0" />
        </Switch>
      </Router>
    );
  }
};

AppRouter.propTypes = {

};

AppRouter.defaultProps = {

};
