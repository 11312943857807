/* eslint-disable max-len */
import React, {Component} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import get from 'lodash/get';
import {Table} from 'react-bootstrap';

import Flex from 'components/ui/Flex';
import Loading from 'components/common/Loading';
import {withFirebase} from 'components/Firebase';
import {getInfoByExpiry, getAssetByModelid, getInfoByMaturity,getFormattedMonthByExpiry} from 'mocks/sample-models';


import './MarketActivitiesTable.scss';

class MarketActivitiesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {allTrades: [], loading: false};
  }

  componentDidMount() {
   this.setState({loading: true});

    this.unsubscribe = this.props.firebase  // .orderBy('created')  add it when is ready 
        .fstor.collection('Trades').orderBy('created', 'desc')
        .onSnapshot((snapshot) => {
          const allTrades = [];

          snapshot.forEach((doc) => {
            const data = doc.data();
            const asset = getAssetByModelid(data.symbol.modelid);
            //const contract = getInfoByExpiry(data.symbol.expiry);
            const timeStr = data.created.toDate().toString();
            if ( asset ) {
              allTrades.push({'created': timeStr, 'description': asset.description, 'month': '',
                'expiry': data.symbol.expiry, 'price': data.price, 'quantity': data.quantity, 'id': doc.id});
            } else {
              console.log(data.symbol, 'could not be found locally');
            }
          }); // Todo, shouldn't be 'allTrades': allTrades,  ???
          this.setState({allTrades, loading: false});
        }); 
  }

  componentWillUnmount() {
    typeof this.unsubscribe === 'function' && this.unsubscribe();
  }

  _renderTableRow = (act, index) => {
    const utcCreatedTime = moment(act.created).utc().format('YYYY-MM-DD HH:mm:ss UTC');
    return (
      <tr key={`${utcCreatedTime}-${index}`}>
        <td>
          {utcCreatedTime}
        </td>
        <td style={{textTransform: 'capitalize'}}>
          {act.description}
        </td>
        <td>
          {getFormattedMonthByExpiry(act.expiry) /*get(getInfoByMaturity(act.month), 'formattedmonth', act.month)*/}
        </td>
        <td>
          {act.expiry || 'no value'}
        </td>
        <td>
          {act.price}
        </td>
        <td>
          {act.quantity}
        </td>
      </tr>
    );
  }

  _renderTableRows = () => {
    return map(this.state.allTrades, this._renderTableRow);
  }

  render() {
    return (
      <Flex size="1" className="market-activities-table">
        {this.state.loading ? <Loading variant="white" /> : (
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Time Stamp</th>
                <th>Asset</th>
                <th>Maturity</th>
                <th>Expiry</th>
                <th>Price</th>
                <th>Volume Traded</th>
              </tr>
            </thead>
            <tbody>
              {this._renderTableRows()}
            </tbody>
          </Table>
        )}
      </Flex>
    );
  }
};

MarketActivitiesTable.propTypes = {
  firebase: PropTypes.object.isRequired,
};

MarketActivitiesTable.defaultProps = {

};

export default withFirebase(MarketActivitiesTable);
