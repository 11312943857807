/* eslint-disable max-len */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import get from 'lodash/get';

import Flex from 'components/ui/Flex';
import {withFirebase} from 'components/Firebase';

import {EXPIRIES,EXPIRIES_INFO, getAssetByModelid, getInfoByExpiry} from 'mocks/sample-models';

import './AssetTradeForm.scss';
 
class AssetTradeForm extends Component {
  constructor(props) { 
    super(props); 
   
    this.state = {
      bidPrice: null,
      askPrice: null,
      midPrice: null,
      quantity: 10, 
    };
     
  }

  async componentDidMount() {
    
   /* var d=await this.props.firebase.fstor.doc(`Synthetics-r2/${this.props.modelId}`).get();
      if (d.exists) {
        console.log('component mount');
        const expiries = d.data().expiries;
            const data = []; // ToDo: Ugly code, replace it for some nice js.
            for (const key in expiries) {
              if ({}.hasOwnProperty.call(expiries, key)) {
                if(Date.parse(key)>=new Date()){
                  data.push(key);
              }
              }
            };
            data.sort(this.date_sort_asc);
            this.setState({
              expiryOptions: data,
              expiry:data[0],
              isModelIdChanged:true
            });
      }*/
      console.log('state in asset tradeform');
      console.log(this.state);
    // TODO: to replace this fixed date value with current date
    this.refreshData();
  }
  clearArray=(array) => {
    while (array.length) {
      array.pop();
    }
  }
  async componentDidUpdate(prevProps) {
    if(prevProps.modelId !== this.props.modelId){
       
      var d=await this.props.firebase.fstor.doc(`Synthetics-r2/${this.props.modelId}`).get();
      if (d.exists) {
        
let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        const expiries = d.data().expiries;
            const data = []; // ToDo: Ugly code, replace it for some nice js.
            this.clearArray(EXPIRIES_INFO);
            var mCount=1;
            for (const key in expiries) {
              if ({}.hasOwnProperty.call(expiries, key)) {
                if(Date.parse(key)>=new Date()){
                  data.push(key);    
                  
              }
              }
            };
            data.sort(this.date_sort_asc);
            for(const d in data){
              let monthNumber = (new Date(Date.parse(data[d])).getMonth()+1);
              let monthName = monthNames[monthNumber];
              let year=new Date(Date.parse(data[d])).getFullYear(); 
              if(monthNumber==12){
                monthName=monthNames[0];
                year=year+1;
              }
              EXPIRIES_INFO.push({
                expiry:data[d],
                month:mCount+'m',
                formattedmonth:monthName+'-'+year
              });
              mCount+=1;
            }
            console.log('Expiries');
            console.log(EXPIRIES_INFO);
            this.setState({
              expiryOptions: data,
              expiry:data[0],
              isModelIdChanged:true
            });
      }
    }
    if (prevProps.modelId !== this.props.modelId || prevProps.expiry !== this.props.expiry) {
      this.refreshData();
    } 
  }
    date_sort_asc = (date1, date2) =>{
    // This is a comparison function that will result in dates being sorted in
    // ASCENDING order. As you can see, JavaScript's native comparison operators
    // can be used to compare dates. This was news to me.
    if (Date.parse(date1) > Date.parse(date2)) return 1;
    if (Date.parse(date1) < Date.parse(date2)) return -1;
    return 0;
  };
  refreshData = () => {
    var expiry=this.props.expiry;
      
    console.log(this.state);
    if(this.state.isModelIdChanged){
      console.log('modelid changed');
      this.setState({ 
        isModelIdChanged:false
      });
    expiry=this.state.expiry;
    this.props.onExpiryChange(expiry);
    }
    console.log(expiry);
    this.setState({
      bidPrice: null,
      askPrice: null,
      midPrice: null,
    });
    
    // Best Bid
    this.props.firebase.fstor
        .collection('OrderBooks').doc(`${this.props.modelId}<${expiry}>`)
        .collection('BestBids')
        .orderBy('created', 'desc').limit(1)
        .get().then( (querySnapshot) => {
          querySnapshot.forEach( (doc) => (
            this.setState({bidPrice: parseFloat(doc.data().price).toFixed(2)})
          ));
        });

    // Latest price
    this.props.firebase.fstor
        .collection(`Synthetics-r2/${this.props.modelId}/${expiry}`)
        .orderBy('id', 'desc').limit(1)
        .get().then( (querySnapshot) => {
          querySnapshot.forEach( (doc) => {
            this.setState({midPrice: parseFloat(doc.data().value).toFixed(2)})
        })
        });

    // Best Offer
    this.props.firebase.fstor
        .collection('OrderBooks').doc(`${this.props.modelId}<${expiry}>`)
        .collection('BestOffers')
        .orderBy('created', 'desc').limit(1)
        .get().then( (querySnapshot) => {
          querySnapshot.forEach( (doc) => (
            this.setState({askPrice: parseFloat(doc.data().price).toFixed(2)})
          ));
        });
  }

  handleAskSubmit = (e) => {
    e.preventDefault();
    this.props.firebase.ordersRef.add({
      'symbol': {'modelid': parseInt(this.props.modelId), 'expiry': this.props.expiry},
      'price': parseFloat(this.state.askPrice),
      'quantity': this.state.quantity,
      'side': 'ask',
      'party': this.props.userId,
      'type': 'limit',
      'state': 'pending new',
      'created': this.props.firebase.fieldValue.serverTimestamp(), // Needed for ordering.
    }).then(() => {
      console.log('Ask Sent for price', this.state.askPrice, 'quantity', this.state.quantity);
    });
  }

  handleBidSubmit = (e) => {
    e.preventDefault(); 
    this.props.firebase.ordersRef.add({
      'symbol': {'modelid': parseInt(this.props.modelId), 'expiry': this.props.expiry},
      'price': parseFloat(this.state.bidPrice),
      'quantity': this.state.quantity,
      'side': 'bid',
      'party': this.props.userId, /* will be user */
      'type': 'limit',
      'state': 'pending new',
      'created': this.props.firebase.fieldValue.serverTimestamp(), // Needed for ordering.
    }).then(() => {
      console.log('Bid Sent for price', this.state.bidPrice, 'quantity', this.state.quantity);
    });
  }

  priceFormat = (value) => {
    return (value && `${value}`.indexOf('.') < 0) ? `${value}.00` : value;
  }

  priceFieldLeave = (field) => (e) => {
    this.setState({[field]: this.priceFormat(e.target.value.replace(/\s/g, ''))});
  }

  fieldChange = (field) => (e) => {
    this.setState({[field]: e.target.value});
  }

  onExpiryChange = (e) => {
    const value = e.target.value;
    this.props.onExpiryChange(value);
  }

  render() {
    const {quantity, bidPrice, askPrice, midPrice, expiryOptions} = this.state;
    const asset = getAssetByModelid(this.props.modelId) || {};
    console.log('expiries-dropdown'+expiryOptions);
    return (
      <div className="asset-trade-form">
        <p className="section-title" style={{color: 'white'}}>Order Entry</p>
        <Flex style={{height: '100%', paddingBottom: '24px'}} align="center">
          <Flex direction="vertical">
            <Flex className="asset-trade-form__line">
              <div className="asset-trade-form__asset-desc-key">
                Asset
              </div>
              <div>
                <strong style={{textTransform: 'capitalize'}}>{asset.description}</strong>
              </div>
            </Flex>
            <Flex className="asset-trade-form__line">
              <div className="asset-trade-form__asset-desc-key">
                Expiry
              </div>
              <div>
                <div className="select">
                  <select defaultValue={this.props.expiry} onChange={this.onExpiryChange}>
                    {map(expiryOptions, (expiry) => (
                      <option value={expiry}>{expiry}</option>
                    ))}
                  </select>
                </div>
              </div>
            </Flex>
            <Flex className="asset-trade-form__line">
              {this.props.expiry ? (
                <>
                  <div className="asset-trade-form__asset-desc-key">
                    Maturity
                  </div>
                  <div>
                    {get(getInfoByExpiry(this.props.expiry), 'formattedmonth', '')}
                  </div>
                </>
              ) : null}
            </Flex>
            <Flex className="asset-trade-form__line">
              <div className="asset-trade-form__asset-desc-key">
                Volume
              </div>
              <div>
                <input type="text" value={quantity} onChange={this.fieldChange('quantity')} />
              </div>
            </Flex>
          </Flex>
          <Flex direction="vertical" size="1" align="center">
            <Flex justify="center">
              <Flex direction="vertical" className="asset-trade-form__field-bid">
                <div className="asset-trade-form__line">
                  <input type="text" value={bidPrice ||  ''} onChange={this.fieldChange('bidPrice')} onBlur={this.priceFieldLeave('bidPrice')} style={{backgroundColor: '#66BB6A', color: 'white'}} />
                </div>
                <div className="asset-trade-form__line asset-trade-form__field-bid-title">
                  BID
                </div>
              </Flex>
              <Flex direction="vertical" className="asset-trade-form__field-bid">
                <div className="asset-trade-form__line">
                  <input type="text" value={midPrice || ''} style={{backgroundColor: '#FCC102', color: 'black'}} disabled/>
                </div>
                <div className="asset-trade-form__line asset-trade-form__field-bid-title">
                  MID
                </div>
              </Flex>
              <Flex direction="vertical" className="asset-trade-form__field-bid">
                <div className="asset-trade-form__line">
                  <input type="text" value={askPrice || ''} onChange={this.fieldChange('askPrice')} onBlur={this.priceFieldLeave('askPrice')} style={{backgroundColor: '#FB0400', color: 'white'}} />
                </div>
                <div className="asset-trade-form__line asset-trade-form__field-bid-title">
                  ASK
                </div>
              </Flex>
            </Flex>
            <Flex>
              <div style={{marginTop: '26px'}}>
                <div className="asset-trade-form__ctas">
                  <button onClick={this.handleBidSubmit} className="asset-trade-form__btn asset-trade-form__buy-btn">BUY</button>
                  <button onClick={this.handleAskSubmit} className="asset-trade-form__btn asset-trade-form__sell-btn">SELL</button>
                </div>
              </div>
            </Flex>
          </Flex>
        </Flex>
      </div>
    );
  }
}

AssetTradeForm.propTypes = {
  modelId: PropTypes.string,
  userId: PropTypes.string.isRequired,
  firebase: PropTypes.object.isRequired,
  onExpiryChange: PropTypes.func,
  expiry: PropTypes.string,
};

AssetTradeForm.defaultProps = {
  userId: 'client-1',
};

export default withFirebase(AssetTradeForm);
