import React, {PureComponent} from 'react';
import classnames from 'classnames';
import map from 'lodash/map';

import {withFirebase} from 'components/Firebase';
import {getAssetByModelid} from 'mocks/sample-models';

import './LedTextHeadline.scss';

class LedTextHeadline extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {lastTrades: [], loading: false};
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  componentDidMount() {
    this.setState({loading: true});

    this.unsubscribe = this.props.firebase
        .fstor.collection('Prices-r2').where('date', '>=', '2019-12-31')
        .onSnapshot((snapshot) => {
          const lastTrades = [];
          const lastModelids = [];

          snapshot.forEach((doc) => {
            const data = doc.data();
            const priceTrend = (Math.random() < 0.65) ? 'up' : 'down';
            const asset = getAssetByModelid(data.modelid);
            if (asset && !lastModelids.find( (modelid) => (modelid === asset.modelid)) ) {
              lastTrades.push({'name': asset.description, 'price': data.value, 'priceTrend': priceTrend, 'id': doc.id});
              lastModelids.push(asset.modelid);
            }
          });
          this.setState({lastTrades, loading: false});
        });
  }

  render() {
    const scrollText = map(this.state.lastTrades, (asset) => {
      const className = classnames(
          'led-text-headline__scroll-text',
          {
            'led-text-headline__scroll-text--green': asset.priceTrend === 'up',
            'led-text-headline__scroll-text--red': asset.priceTrend === 'down',
            'led-text-headline__scroll-text--unchange': asset.priceTrend === '',
          },
      );
      return (
        <span className={className}>
          {asset.name} {asset.price}
          <span>{asset.priceTrend === 'up' ? '▲' : (asset.priceTrend === 'down' ? '▼' : '')}</span>
        </span>
      );
    });
    return (
      <div className="led-text-headline">
        <div className="led-text-headline__scroll-wrapper">
          <p className="led-text-headline__scroll-text-wrapper">
            {scrollText}
          </p>
          <p className="led-text-headline__scroll-text-wrapper--second">
            {scrollText}
          </p>
        </div>
      </div>
    );
  }
}

export default withFirebase(LedTextHeadline)
