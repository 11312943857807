import React, {Component} from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import {getInfoByExpiry, getAssetByModelid} from 'mocks/sample-models';
import Flex from 'components/ui/Flex';
import Loading from 'components/common/Loading';
import {withFirebase} from 'components/Firebase';

import './AssetOrderBook.scss';

class AssetOrderBook extends Component {
  constructor(props) {
    super(props);
    this.state = {askLevels: [], bidLevels: []};
  }

  queryTests() {
    this.props.firebase.fstor.collection('OrderBooks').get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        console.log(`${doc.id} => ${doc.data()}`);
      });
    });
    this.props.firebase.fstor.collection('OrderBooks').doc('351<2021-05-14>').collection('ask').get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        console.log(`${doc.id} => ${doc.data()}`);
      });
    });
  }

  refreshData = () => {
    console.log('refresh data');
    this.setState({loading: true});

    this.unsubscribeAsk = this.props.firebase
        .fstor.collection('OrderBooks').doc(`${this.props.modelId}<${this.props.expiry}>`).collection('ask')
        .onSnapshot((snapshot) => {
          const askLevels = [];

          snapshot.forEach((askLevelDoc) => {
            const askLevelData = askLevelDoc.data();
            const asset = getAssetByModelid(askLevelData.symbol.modelid);
            const contract = getInfoByExpiry(askLevelData.symbol.expiry);
            if ( asset && contract) {
              askLevels.push({'price': askLevelData.price,
                'volume': askLevelData.volume, 'id': askLevelDoc.id});
            } else {
              console.log(askLevelData.symbol, 'could not be found locally');
            }
          });
          this.setState({askLevels: askLevels, loading: false});
        });

    this.unsubscribeBid = this.props.firebase
        .fstor.collection('OrderBooks').doc(`${this.props.modelId}<${this.props.expiry}>`).collection('bid')
        .onSnapshot((snapshot) => {
          const bidLevels = [];

          snapshot.forEach((bidLevelDoc) => {
            const bidLevelData = bidLevelDoc.data();
            const asset = getAssetByModelid(bidLevelData.symbol.modelid);
            const contract = getInfoByExpiry(bidLevelData.symbol.expiry);
            if ( asset && contract) {
              bidLevels.push({'price': bidLevelData.price,
                'volume': bidLevelData.volume, 'id': bidLevelDoc.id});
            } else {
              console.log(bidLevels.symbol, 'could not be found locally');
            }
          });
          this.setState({bidLevels: bidLevels, loading: false});
        });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.modelId !== this.props.modelId || prevProps.expiry !== this.props.expiry) {
      this.unsubscribeAsk();
      this.unsubscribeBid();
      this.refreshData();
    }
  }

  componentDidMount() {
    this.refreshData();
  }

  componentWillUnmount() {
    /* this.unsubscribeAsk(); */
  }

  bidLine(bid) {
    return (bid ?
      (<div className="asset-order-book__line asset-order-book__line--bid">{bid}</div>) :
      (<div className="asset-order-book__line"></div>)
    );
  };

  priceLine(price) {
    return (<div className="asset-order-book__line asset-order-book__line--price">{price}</div>);
  };

  askLine(ask) {
    return (ask ?
      (<div className="asset-order-book__line asset-order-book__line--ask">{ask}</div>) :
      (<div className="asset-order-book__line"></div>)
    );
  };

  distributeData = () => {
    const bidsList = [];
    const asksList = [];
    const pricesList = [];
    const bids = this.state.bidLevels;
    const asks = this.state.askLevels;

    bids.sort( (a, b) => (parseFloat(a.price) - parseFloat(b.price)));
    asks.sort( (a, b) => (parseFloat(a.price) - parseFloat(b.price)));

    let bIndex = 0;
    let aIndex = 0;

    while ( bIndex < bids.length || aIndex < asks.length) {
      if ( aIndex >= asks.length ) {
        pricesList.unshift(this.priceLine(bids[bIndex].price));
        bidsList.unshift(this.bidLine(bids[bIndex].volume));
        asksList.unshift(this.askLine(null));
        bIndex++;
      } else if ( bIndex >= bids.length) {
        pricesList.unshift(this.priceLine(asks[aIndex].price));
        bidsList.unshift(this.bidLine(null));
        asksList.unshift(this.askLine(asks[aIndex].volume));
        aIndex++;
      } else {
        const bid_price = parseFloat(bids[bIndex].price)
        const ask_price = parseFloat(asks[aIndex].price)
        if ( bid_price < ask_price ) { // Bids go first.
          pricesList.unshift(this.priceLine(bids[bIndex].price));
          bidsList.unshift(this.bidLine(bids[bIndex].volume));
          asksList.unshift(this.askLine(null));
          bIndex++;
        } else if ( bid_price > ask_price ) { // Asks go first.
          pricesList.unshift(this.priceLine(asks[aIndex].price));
          bidsList.unshift(this.bidLine(null));
          asksList.unshift(this.askLine(asks[aIndex].volume));
          aIndex++;
        } else { // Bids and Asks at that price. both go in.
          pricesList.unshift(this.priceLine(asks[aIndex].price));
          bidsList.unshift(this.bidLine(bids[bIndex].volume));
          asksList.unshift(this.askLine(asks[aIndex].volume));
          aIndex++;
          bIndex++;
        }
      }
    }
    return {bidsList, asksList, pricesList};
  }

  render() {
    const {bidsList, asksList, pricesList} = this.distributeData();
    const asset = getAssetByModelid(this.props.modelId);

    if (!asset) {
      return null;
    }

    return (
      <Flex direction="vertical" className="asset-order-book" style={{width: '400px'}}>
        <p className="section-title">Order Book - Market</p>
        <Flex direction="vertical" className="asset-order-book__desc">
          <Flex>
            <div className="asset-order-book__desc-line asset-order-book__desc-title">Asset</div>
            <div className="asset-order-book__desc-line">{asset.description}</div>
          </Flex>
          <Flex>
            <div className="asset-order-book__desc-line asset-order-book__desc-title">Expiry</div>
            <div className="asset-order-book__desc-line">{this.props.expiry}</div>
          </Flex>
          <Flex>
            <div className="asset-order-book__desc-line asset-order-book__desc-title">Maturity</div>
            <div className="asset-order-book__desc-line">{get(getInfoByExpiry(this.props.expiry), 'formattedmonth', '')}</div>
          </Flex>
        </Flex>
        {this.state.loading ? (
          <Loading />
        ) : (
          <Flex align="stretch" size="1" style={{width: '100%'}}>
            <Flex size="1" direction="vertical" className="asset-order-book__column">
              <div className="asset-order-book__title asset-order-book__title--bid">
                Bid Size
              </div>
              {bidsList}
            </Flex>
            <Flex size="1" direction="vertical" className="asset-order-book__column">
              <div className="asset-order-book__title asset-order-book__title--price">
                Price
              </div>
              {pricesList}
            </Flex>
            <Flex size="1" direction="vertical" className="asset-order-book__column">
              <div className="asset-order-book__title asset-order-book__title--ask">
                Ask Size
              </div>
              {asksList}
            </Flex>
          </Flex>
        )}
      </Flex>
    );
  }
}

AssetOrderBook.propTypes = {
  firebase: PropTypes.object.isRequired,
  modelId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  expiry: PropTypes.string,
};

export default withFirebase(AssetOrderBook);
