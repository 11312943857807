import React, {Component} from 'react';
import get from 'lodash/get';
import {withRouter} from 'react-router-dom';

import getQueryVariable from 'utils/getQueryVariable';
import Simulator from 'components/Simulator';

import LedTextHeadline from './components/LedTextHeadline';
import AssetGlobalTradeBoard from './components/AssetGlobalTradeBoard';
import AssetList from './components/AssetList';
import AssetTrade from './AssetTrade';
import {SAMPLE_ASSETS} from 'mocks/sample-models';
import trim from 'lodash/trim';
import filter from 'lodash/filter';
import PropTypes from 'prop-types';
import {withFirebase} from 'components/Firebase';
import Loading from 'components/common/Loading';
import authentication from '@kdpw/msal-b2c-react';
import decodeJWT from 'jwt-decode'; 
class DemoRevampScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expiry: '',
      simulatorVisible: false,
      expiryOptions: [],
      isInitialDataLoading:true
    };
  }

  onExpiryChange = (value) => {
    this.setState({
      expiry: value,
    });
  }

  onStateChange = (newState) => {
    this.setState(newState);
  }

  closeSimulator = () => {
    this.setState({
      simulatorVisible: false, 
    });
  }

  openSimulator = () => {
    this.setState({
      simulatorVisible: true,
    });
  }
  currentUser() {
    const decoded = decodeJWT(authentication.getAccessToken());     
    return {
      name: decoded.name,
      firstName: decoded.given_name,
      lastName: decoded.family_name,
      emails: decoded.emails,
      city: decoded.city,
      country: decoded.country,
    };
  }
 async componentDidMount  (){
  let userEmail=this.currentUser().emails[0];    
  var userDoc=await this.props.firebase.fstor.collection('UserAccess').doc(userEmail).get(); 
   
  if(userDoc.exists){     
    var userAccess=userDoc.data()   
    let isAdmin=userAccess.IsAdmin  
    if(userAccess.IsActive==true){       
    this.setState({ 
      isAcces:true,
      IsAdmin:isAdmin
    });
  }else{     
    this.setState({ 
      isAcces:false
    });
  }
  }else{

    console.log('user data not exists auth is false');
    this.setState({ 
      isAcces:false
    });
  }
  
   const allAssets=[]
   var doc={}
   if(this.state.IsAdmin===true){
      doc=await this.props.firebase.fstor.collection('Assets').where("is_visible_in_ui","==",true).get(); 
    
    }else{
      var accessibleAssets=await this.props.firebase.fstor.collection('UserAccess').doc(userEmail).collection('AssetsAccess').get(); 
      var accessibleAssetIds=[]
       
      accessibleAssets.forEach((d)=>{  
        accessibleAssetIds.push(parseInt(d.id))
      })
      console.log(accessibleAssetIds)
      if(accessibleAssetIds.length>0){
        doc=await this.props.firebase.fstor.collection('Assets').where("modelid","in",accessibleAssetIds).where("is_visible_in_ui","==",true).get();  
      }else{
        this.setState({ 
          isAcces:false
        });
      }
    }
    
    doc.forEach( (d) => {       
      const data = d.data(); 
      allAssets.push({
        modelid:data.modelid,
        description:data.description
      });
      SAMPLE_ASSETS.push({
        modelid:data.modelid,
        description:data.description.toUpperCase()
      });
    }); 
    this.setState({
      isInitialDataLoading:false,
      assetData: allAssets 
    });    
      
 /* var d= await this.props.firebase.fstor.doc(`Synthetics-r2/${allAssets[1].modelid}`).get();
  if (d.exists) {
    
    const expiries = d.data().expiries;
        const data = []; // ToDo: Ugly code, replace it for some nice js.
        for (const key in expiries) {
          if ({}.hasOwnProperty.call(expiries, key)) {
            if(Date.parse(key)>=new Date()){
              data.push(key);
          }
          }
        };
        data.sort(this.date_sort_asc);
        this.setState({
          expiryOptions: data,
          expiry:data[0],
          isModelIdChanged:true,
          isInitialDataLoading:false,
          modelId:allAssets[0].modelid
        });
         
  }else{
    console.log('noexpiries found');
    this.setState({
      isInitialDataLoading:false
    });
  }*/
  
}
  render() { 
    
    const userId = getQueryVariable('userid') || 'client-1';
    
    var modelId = getQueryVariable('modelid') || get(this.props, 'match.params.modelId');
     
    const expiry = this.state.expiry || getQueryVariable('expiry');  // Todo: no hardwiring of beginning of data.
    if(this.state.isAcces===false) {     
      authentication.signOut();
      return;
    }
    if(this.state.isInitialDataLoading){
      return (<Loading></Loading>);
    }else if(this.state.isInitialDataLoading==false && this.state.isAcces==true){ 
    return (
      <>
        <LedTextHeadline />
        <AssetList modelId={modelId} expiry={expiry} IsAdmin={this.state.IsAdmin} openSimulator={this.openSimulator} />
        <AssetTrade expiry={expiry} userId={userId} modelId={modelId} onExpiryChange={this.onExpiryChange} />
        <AssetGlobalTradeBoard />
        <Simulator modelId={modelId} expiry={expiry} userId={userId} visible={this.state.simulatorVisible} close={this.closeSimulator} />
      </>
    );
    }
     
  }
};
DemoRevampScreen.propTypes = {
  
  firebase: PropTypes.object,
  
};
DemoRevampScreen.defaultProps = {};
export default withRouter(withFirebase(DemoRevampScreen));
