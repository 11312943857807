/* eslint-disable max-len */
import find from 'lodash/find';

export const USER_ID = 'barn';

export const MODEL_IDS = [425, 555, 588, 631, 640, 695, 718, 739, 742, 744];

export const EXPIRIES = [
  '2020-06-15',
  '2020-07-15',
  '2020-08-14',
  '2020-09-15',
  '2020-10-15',
  '2020-11-13',
  '2020-12-15',
  '2021-01-15',
  '2021-02-12',
  '2021-03-15',
  '2021-04-15',
  '2021-05-14',
];

export const EXPIRIES_INFO = [
 /* {expiry: '2020-06-15', month: '1m', formattedmonth: 'Jul-2020'},
  {expiry: '2020-07-15', month: '2m', formattedmonth: 'Aug-2020'},
  {expiry: '2020-08-14', month: '3m', formattedmonth: 'Sep-2020'},
  {expiry: '2020-09-15', month: '4m', formattedmonth: 'Oct-2020'},
  {expiry: '2020-10-15', month: '5m', formattedmonth: 'Nov-2020'},
  {expiry: '2020-11-13', month: '6m', formattedmonth: 'Dec-2020'},
  {expiry: '2020-12-15', month: '7m', formattedmonth: 'Jan-2021'},
  {expiry: '2021-01-15', month: '8m', formattedmonth: 'Feb-2021'},
  {expiry: '2021-02-12', month: '9m', formattedmonth: 'Mar-2021'},
  {expiry: '2021-03-15', month: '10m', formattedmonth: 'Apr-2021'},
  {expiry: '2021-04-15', month: '11m', formattedmonth: 'May-2021'},
  {expiry: '2021-05-14', month: '12m', formattedmonth: 'Jun-2021'}, */
];

export const SAMPLE_ASSETS = [
  /*{modelid: 425, description: 'Black Sea wheat oldcrop'},
  {modelid: 555, description: 'Asia PKE'},
  {modelid: 588, description: 'Rotterdam rapeseed meal'},
  {modelid: 631, description: 'North West Europe propane'},
  {modelid: 640, description: 'Ukraine corn oldcrop'},
  {modelid: 695, description: 'China HDPE'},
  {modelid: 718, description: 'Australia HCC'},
  {modelid: 739, description: 'Ukraine corn newcrop'},
  {modelid: 742, description: 'Saupaulo ESALQ ethanol'},
  {modelid: 744, description: 'Black Sea wheat newcrop'},*/
];

export const getAssetByModelid = (modelid) => {
  return SAMPLE_ASSETS.find((asset) => `${asset.modelid}` === `${modelid}`);
};


export const getInfoByExpiry =
  (expiry) => find(EXPIRIES_INFO, (info) => info.expiry === expiry);

export const getInfoByMaturity =
  (month) => find(EXPIRIES_INFO, (info) => info.month === month);

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const getFormattedMonthByExpiry = 
  (expiry) => {
    let monthNumber = (new Date(Date.parse(expiry)).getMonth()+1);
              let monthName = monthNames[monthNumber];
              let year=new Date(Date.parse(expiry)).getFullYear(); 
              if(monthNumber==12){
                monthName=monthNames[0];
                year=year+1;
              }
              return monthName + '-' + year;
  }
