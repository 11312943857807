/* eslint-disable max-len */
import React, {Component} from 'react';
import {
  LineChart, Line, XAxis, YAxis, Legend, Tooltip,
} from 'recharts';
import moment from 'moment';
import map from 'lodash/map';
import round from 'lodash/round';

import Flex from 'components/ui/Flex';
import Loading from 'components/common/Loading';
import PropTypes from 'prop-types';
import {withFirebase} from 'components/Firebase';

import './MarketChart.scss';

class MarketChart extends Component {
  constructor(props) {
    super(props);
    this.chartContainer = React.createRef();
    this.state = {
      chartWidth: 400,
      chartHeight: 300,
      fwdData: [],
      loading: false,
    };
  }

  componentDidMount() {
    const containerDom = this.chartContainer.current;
    this.setState({
      chartWidth: containerDom && containerDom.clientWidth,
      chartHeight: containerDom && containerDom.clientHeight,
    });
    // Data loading chunk
    if(this.props.modelId!=0){
    this.refreshData();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.modelId !== prevProps.modelId) {
      this.refreshData();
    }
  }

  refreshData = () => {
    this.setState({loading: true});

    this.props.firebase.fstor.doc(`Synthetics-r2/${this.props.modelId}`)         
        .get().then( (doc) => {
          if (doc.exists) {
            const expiries = doc.data().expiries;
            var data = []; // ToDo: Ugly code, replace it for some nice js.
             
            for (const key in expiries) {
              if ({}.hasOwnProperty.call(expiries, key)) {
                if(Date.parse(key)>=new Date()){
                const fields = expiries[key];
                data.push({
                  expiryDate:moment(key,'YYYY-MM-DD'),
                  expiry: moment(key, 'YYYY-MM-DD').format('MMM-YYYY'),
                  current: fields.curr_value,
                  previous: fields.prev_value,
                  change: fields.change,
                });
              }
              }
            };
           // console.log('before sort:');
           // console.log(data);
            data= data.sort((a, b) => b.expiryDate < a.expiryDate ? 1: -1);
           // console.log('after sort:');
           // console.log(data);
            this.setState({...this.transformChartData(data)});
          } else {
            console.log('Did not find document describing prices... ToDo');
          }
          this.setState({loading: false});
        }).catch((error) => {
          this.setState({loading: false});
          console.log('Error getting document:', error);
        });
  }

  transformChartData(data) {
    let minValue = null;
    let maxValue = null;
    let firstTick = null;
    let lastTick = null;
    let stepSize = 10;
    let range = 100;
    const chartData = map(data, ({current, previous, expiry}) => {
      const numCurrent = Number(current);
      const numPrevious = Number(previous);
      if (numCurrent !== null) {
        if (!minValue && !maxValue) {
          minValue = numCurrent;
          maxValue = numCurrent;
        }
        if (numCurrent > maxValue) {
          maxValue = numCurrent;
        } else if (numCurrent < minValue) {
          minValue = numCurrent;
        }
      }

      if (numPrevious !== null) {
        if (!minValue && !maxValue) {
          minValue = numPrevious;
          maxValue = numPrevious;
        }
        if (numPrevious > maxValue) {
          maxValue = numPrevious;
        } else if (numPrevious < minValue) {
          minValue = numPrevious;
        }
      }

      return {
        expiry,
        current: round(numCurrent, 2),
        previous: round(numPrevious, 2),
      };
    });
    const valueRange = Math.ceil(maxValue - minValue);
    if (valueRange > range) {
      stepSize = round((valueRange / range) * stepSize);
      firstTick = round(minValue, -1);
      firstTick = firstTick < 0 ? 0 : firstTick;
      lastTick = round(maxValue, -1);
    } else {
      range = Math.ceil((1.4 * valueRange));
      stepSize = Math.ceil(range / 6);
      firstTick = Math.floor(minValue);
      firstTick = firstTick < 0 ? 0 : firstTick;
      lastTick = Math.ceil(maxValue);
    }
    return {
      fwdData: chartData,
      stepSize,
      firstTick,
      lastTick,
      domainStart: firstTick - stepSize,
      domainEnd: lastTick + stepSize,
    };
  }

  getPriceTicks() {
    const ticks = [];
    const {firstTick, stepSize, domainStart, domainEnd} = this.state;
    const numOfTicks = Math.ceil((domainEnd - domainStart) / stepSize);
    const roundedFirstTick = Math.floor(firstTick);
    for (let i = 0; i < numOfTicks; i += 1) {
      const tickValue = roundedFirstTick + (stepSize * i);
      ticks.push(`${tickValue}.00`);
    }
    return ticks;
  }

  render() {
    const {
      domainStart,
      domainEnd,
      chartWidth,
      chartHeight,
      fwdData,
      stepSize,
      loading,
    } = this.state;
    return (
      <Flex direction="vertical" className="market-chart">
        <p className="section-title" style={{backgroundColor: '#333', color: 'white'}}>Forward Curve</p>
        <div style={{width: '100%', height: '100%'}} ref={this.chartContainer}>
          {(!loading && chartWidth) ? (fwdData.length ? (
            <LineChart
              width={chartWidth - 12}
              height={chartHeight - 24}
              data={fwdData}
            >
              <XAxis dataKey="expiry"/>
              <YAxis ticks={this.getPriceTicks()} domain={[domainStart, domainEnd + stepSize]} tickFormatter={(tick) => tick} interval={0}/>
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="previous" dot={false} stroke="#4682b4" />
              <Line type="monotone" dataKey="current" dot={false} stroke="#FCC102" />
            </LineChart>
          ) : (
            <p style={{textAlign: 'center'}}>No Data!</p>
          )) : (
            <Loading />
          )}
        </div>
      </Flex>
    );
  }
}

MarketChart.propTypes = {
  modelId: PropTypes.string.isRequired,
  firebase: PropTypes.object.isRequired,
};

MarketChart.defaultProps = {

};

export default withFirebase(MarketChart);
