/* eslint-disable max-len */
import React, {Component, useEffect} from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import trim from 'lodash/trim';
import filter from 'lodash/filter';
import classnames from 'classnames';
import {Link, withRouter} from 'react-router-dom';

import {withFirebase} from 'components/Firebase';
import Flex from 'components/ui/Flex';
import List, {ListItem} from 'components/ui/List';
import {SAMPLE_ASSETS} from 'mocks/sample-models';
import authentication from '@kdpw/msal-b2c-react';
import './AssetList.scss';
import Loading from 'components/common/Loading';
class AssetList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchPhrase: '',
      marketMaking: 'manual'       
    };
  }

  onAssetSearch = (e) => {
    console.log('on asset search triggered');
    this.setState({
      searchPhrase: e.target.value,
    });
    let assets= filter(SAMPLE_ASSETS, ((asset) => asset.description.toLowerCase().indexOf(e.target.value) > -1));
    this.setState({ assetData: assets });
  }

  filterAsset = () => {
     
    
    const isCompleteLoading=false;
    const toggleComplete= async(props)=> {
      
     
       isCompleteLoading=true;
    } ;
    
    toggleComplete(this.props) ;
     
  }

  marketMakingBackendCall = (modelId, expiry) => {
    // modelId, expiry and this.props.firebase are ready
    const doc = this.props.firebase.fstor.collection('BackendConfig').doc('market-maker');
    doc.update({model_id: parseInt(modelId), expiry: expiry, command: 'create'});
  }

  onMarketMakeChange = (modelId, expiry) => (e) => {
    this.marketMakingBackendCall(modelId, expiry);
    this.setState({
      marketMaking: e.target.value,
    });
  }
    componentDidMount() {
    this.setState({loading: true});
    const searchPhrase = trim(this.state.searchPhrase);       
     if (searchPhrase) {
        let assets= filter(SAMPLE_ASSETS, ((asset) => asset.description.toLowerCase().indexOf(searchPhrase) > -1));
        this.setState({ assetData: assets });
     }else{
      this.setState({ assetData: SAMPLE_ASSETS });
     }
    this.setState({loading: false});
  }
  render() {
    const {modelId} = this.props;
     
    const _renderListItem = (asset) => {
      const listItemClasses = classnames({
        'asset-list__link--active': modelId === `${asset.modelid}`,
      });

      return (
        <ListItem key={asset.modelid}>
          <Link className={listItemClasses} to={`/${asset.modelid}`}>
            {asset.description}
          </Link>
        </ListItem>
      );
    };

    return (
      <div className="asset-list">
        <img className="asset-list__logo" src="/logo.png" alt="OPALai Logo" />
        <div className="asset-list__market-making">
        <p className="section-title" style={{color: 'steelblue', marginBottom: '4px'}}>User: {authentication.getUserName()}</p>
        <Flex justify="center" >
            <button style={{background:'transparent',color:'steelblue',border:'0'}} onClick={authentication.signOut}>Logout</button>
          </Flex>
          {(this.props.IsAdmin===true)?
          <div>
          <p className="sesction-title" style={{color: 'white', marginBottom: '4px'}}>Market</p>
          <Flex justify="center" className="asset-list__control">
            <button onClick={this.props.openSimulator}>Robotic Market</button>
          </Flex>
          </div> : <></>       
          
          }
        </div>
        <p className="section-title" style={{color: 'white', marginBottom: '4px'}}>Assets</p>
        <div className="asset-list__search">
          <input type="text" onChange={this.onAssetSearch} placeholder="Search Asset" />
        </div>
        {(this.state.loading)? <Loading variant="white" /> :
        <div className="asset-list__list">
          <List className="ui-list">
            {map(this.state.assetData, _renderListItem)}
          </List>
          <Flex className="asset-list__market-offset" align="center" justify="center">
            <label>
              <input type="checkbox" />
              Market Offset
            </label>
             <br/> 
          </Flex>
          
        </div>
    }
      </div>
    );
  }
};

AssetList.propTypes = {
  modelId: PropTypes.string,
  expiry: PropTypes.string,
  firebase: PropTypes.object,
  openSimulator: PropTypes.func,   
};

AssetList.defaultProps = {};

export default withRouter(withFirebase(AssetList));
