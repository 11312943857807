import React, {Component} from 'react';

import Firebase, {FirebaseContext} from './components/Firebase';
import Router from './router';

import './scss/global.scss';

export default class App extends Component {
  render() {
    return (
      <React.StrictMode>
        <FirebaseContext.Provider value={new Firebase()}>
          <Router />
        </FirebaseContext.Provider>
      </React.StrictMode>
    );
  }
};
