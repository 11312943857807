/* eslint-disable max-len */
import app from 'firebase/app';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyBLAKzgAFWZJ0pf9O4zDo9VXkD_aMlIVPw',
  authDomain: 'opalx1.firebaseapp.com',
  databaseURL: 'https://opalx1.firebaseio.com',
  projectId: 'opalx1',
  storageBucket: 'opalx1.appspot.com',
  messagingSenderId: '558784206917',
  appId: '1:558784206917:web:676904ef12c9deeda3ab2c',
  measurementId: 'G-PMV1V3S9C7',
};

class Firebase {
  constructor() {      
    console.log('firebase constr');
    if (!app.apps.length) {
      app.initializeApp(firebaseConfig);
}
    
    /* This is different from database which brings the realtime DB */
    this.fieldValue = app.firestore.FieldValue;
    this.fstor = app.firestore();

    this.assetRef = this.fstor.collection('Assets');
    this.ordersRef = this.fstor.collection('Orders');

    console.log('Firebase has been configured');
  }

  /* New Stuff */
  userid = 'barn';
  assetFromModelid = (modelid) => this.fstor.collection('Assets').where('modelid', '==', modelid)
  trades = () => this.fstor.collection('Trades');
  modelidOrders = (modelid, userid) => this.fstor.collection('Orders').where('symbol.modelid', '==', modelid).where('party', '==', userid);
  modelidTrades = (modelid) => this.fstor.collection('Trades').where('symbol.modelid', '==', modelid);
  modelidBids = (modelid) => this.fstor.collection('Orders').where('symbol.modelid', '==', modelid).where('side', '==', 'bid').where('state', '==', 'new');
  modelidOffers = (modelid) => this.fstor.collection('Orders').where('modelid', '==', modelid).where('side', '==', 'ask').where('state', '==', 'new');
}

export default Firebase;
