import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './Flex.scss';

export default function Flex({
  children,
  direction,
  size,
  align,
  justify,
  style,
  className,
  ...rest
}) {
  const flexClasses = classnames(
      className,
      'ui-flex',
      {
        'ui-flex--vertical': direction === 'vertical',
        'ui-flex--horizontal': direction === 'horizontal',
        'ui-flex--align-center': align === 'center',
        'ui-flex--align-flex-start': align === 'flex-start',
        'ui-flex--align-stretch': align === 'stretch',
        'ui-flex--justify-center': justify === 'center',
        'ui-flex--justify-flex-start': justify === 'flex-start',
      },
  );

  const customStyles = {};
  if (size) {
    customStyles.flex = size;
  }

  return (
    <div style={{...customStyles, ...style}} className={flexClasses} {...rest}>
      {children}
    </div>
  );
}

Flex.defaultProps = {
  direction: 'horizontal',
  align: 'flex-start',
  justify: 'flex-start',
  style: {},
};

Flex.propTypes = {
  direction: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  style: PropTypes.object,
  align: PropTypes.string,
  justify: PropTypes.string,
};
