import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {getAssetByModelid} from 'mocks/sample-models';

import {withFirebase} from '../Firebase';
import Flex from '../ui/Flex';

import './index.scss';

class Simulator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bid: '',
      ask: '',
      userId: props.userId,
      successMsgVisible:false
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.modelId !== this.props.modelId || prevProps.expiry !== this.props.expiry) {
      this.setState({
        bid: '',
        ask: '',
        successMsgVisible:false
      });
    }
  }

  onFieldChange = (field) => (e) => {
    this.setState({
      [field]: e.target.value,
    });
  }

  onClose =() =>{
    this.setState({
       successMsgVisible:false
    })
    this.props.close()
  }

  makeMarket = () => {
    const {bid, ask} =  this.state;
    const {expiry, modelId} = this.props;
    const doc = this.props.firebase.fstor.collection('BackendConfig').doc('market-maker');
    doc.update({model_id: parseInt(modelId), expiry: expiry, command: 'sweep one', bid: bid, ask: ask});
     this.setState({
      successMsgVisible:true
     })
  }

  render() {
    const {ask, bid, userId} = this.state;
    const {modelId, expiry} = this.props;
    
    const simulatorClasses = classnames('simulator-modal', {
      'simulator-modal--visible': this.props.visible,
    });

    const overlayClasses = classnames('simulator-modal__overlay', {
      'simulator-modal__overlay--visible': this.props.visible,
    });
 
    return (
      <>
        <div className={simulatorClasses}>
          <Flex direction="vertical">            
            <Flex className="simulator-modal__line">
              <span className="simulator-modal__field-label">Bid Spread</span>
              <input type="text" onChange={this.onFieldChange('bid')} value={bid} />
            </Flex>
            <Flex className="simulator-modal__line">
              <span className="simulator-modal__field-label">Ask Spread</span>
              <input type="text" onChange={this.onFieldChange('ask')} value={ask} />
            </Flex>     
            <Flex className="simulator-modal__line">
              <span className="simulator-modal__field-label">Asset</span>
              <input type="text" disabled value={(modelId!=0)? getAssetByModelid(modelId).description:""} />
            </Flex>
            <Flex className="simulator-modal__line">
              <span className="simulator-modal__field-label">Expiry</span>
              <input type="text" disabled value={expiry}/>
            </Flex>       
            <Flex className="simulator-modal__line">
              <button onClick={this.makeMarket}>
                Make Market
              </button>              
            </Flex> 
             
            <Flex className="simulator-modal__line">
             {this.state.successMsgVisible? <label visible={this.state.successMsgVisible===true} >Successfully triggered to create Orders, orders will be updated automatically in UI, please click close.</label>:''}
            </Flex>
             
            <Flex className="simulator-modal__line">
              <button onClick={this.onClose}>
                Close
              </button>
            </Flex>
          </Flex>
        </div>
        <div className={overlayClasses} onClick={this.props.close}></div>
      </>
    );
  }
};

Simulator.propTypes = {
  modelId: PropTypes.string,
  expiry: PropTypes.string,
  userId: PropTypes.string,
  history: PropTypes.object,
  location: PropTypes.object,
  visible: PropTypes.bool,
  close: PropTypes.func,
};

Simulator.defaultProps = {

};

export default withRouter(withFirebase(Simulator));
