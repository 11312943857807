import React, {Component} from 'react';

import Flex from 'components/ui/Flex';
import MarketActivitiesTable from './MarketActivitiesTable';

import './AssetGlobalTradeBoard.scss';

export default class AssetGlobalTradeBoard extends Component {
  render() {
    return (
      <Flex direction="vertical" className="asset-global-trade-board" align="stretch">
        {/* <Flex direction="vertical" style={{height: '100vh', width: '360px'}}>
          <MarketChart />
          <MarketTrendTable />
        </Flex> */}
        <p className="section-title" style={{color: 'white'}}>Recent Market Activity</p>
        <Flex size="1">
          <MarketActivitiesTable /> 
        </Flex>
      </Flex>
    );
  }
};

AssetGlobalTradeBoard.propTypes = {

};

AssetGlobalTradeBoard.defaultProps = {

};
