import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';
import App from './App';
import authentication from '@kdpw/msal-b2c-react';
authentication.initialize({
    // optional, will default to this
    instance: 'https://login.microsoftonline.com/tfp/', 
    // your B2C tenant
    tenant: 'titium.onmicrosoft.com',
    // the policy to use to sign in, can also be a sign up or sign in policy
    signInPolicy: 'B2C_1_oclive',
    // the the B2C application you want to authenticate with (that's just a random GUID - get yours from the portal)
    applicationId: '35212634-259c-46a3-b84a-74b9587490a7',
    // where MSAL will store state - localStorage or sessionStorage
    cacheLocation: 'sessionStorage',
    // the scopes you want included in the access token
    scopes: ['https://titium.onmicrosoft.com/api/user_impersonation'],
    // optional, the redirect URI - if not specified MSAL will pick up the location from window.href
    redirectUri: 'https://x.opalai.com/',
    postLogoutRedirectUri: window.location.origin,
    resetPolicy: 'B2C_1_oclivepwd',
 
});
authentication.run(() => {
ReactDOM.render(
    <App />,
    document.getElementById('opalai-marketplace'),
);
 // If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
});


