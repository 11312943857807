import React from 'react';
import classnames from 'classnames';

import './Loading.scss';

export default function Loading({variant}) {
  const loadingClass = classnames('loading', {
    'loading--white': variant === 'white',
  });
  return (
    <div className={loadingClass}>
      <div className="loading__dot"></div>
      <div className="loading__dot"></div>
      <div className="loading__dot"></div>
    </div>
  );
}
