import React from 'react';
import PropTypes from 'prop-types';

import './List.scss';

export const ListItem = ({children, ...rest}) => {
  return (
    <li className="ui-list__item" {...rest}>
      {children}
    </li>
  );
};

export default function List({children, ...rest}) {
  return (
    <ul className="ui-list" {...rest}>
      {children}
    </ul>
  );
}

List.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

ListItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
