/* eslint-disable max-len */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import map from 'lodash/map';
import get from 'lodash/get';
import {Table} from 'react-bootstrap';

import Loading from 'components/common/Loading';
import Flex from 'components/ui/Flex';
import {withFirebase} from 'components/Firebase';

import {getInfoByExpiry, getAssetByModelid, getInfoByMaturity,getFormattedMonthByExpiry} from '../../../mocks/sample-models';

import './UserTradeHistory.scss';

class UserTradeHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {userOrders: [], loading: false};
  }


  componentDidMount() {  
     
   this.setState({loading: true});

    this.unsubscribe = this.props.firebase // .orderBy('savedon')  add it when is ready .orderBy('party').orderBy('savedon')
        .fstor.collection('Orders').where('party', '==', this.props.userId)
        .onSnapshot((snapshot) => {
          const userOrders = [];

          snapshot.forEach((orderDoc) => {
            const orderData = orderDoc.data();
            const asset = getAssetByModelid(orderData.symbol.modelid);
            //const contract = getInfoByExpiry(orderData.symbol.expiry);
            if ( asset ) {
              userOrders.push({'state': orderData.state, 'created': orderData.created, 'description': asset.description, 'month': '',
                'expiry': orderData.symbol.expiry, 'side': orderData.side, 'type': orderData.type, 'price': orderData.price,
                'quantity': orderData.quantity, 'fillPrice': orderData.fillPrice, 'id': orderDoc.id});
            } else {
              console.log(orderData.symbol, 'could not be found locally');
            }
          });
          // ToDo: I had to order stuff here.  Not ideal?
          userOrders.sort((a, b) => b.created && a.created ? b.created.seconds - a.created.seconds : 0 );
          this.setState({userOrders, loading: false});
        }); 
  }

  componentWillUnmount() {
    typeof this.unsubscribe === 'function' && this.unsubscribe();
  }

  cancelOrder = (orderId) => (e) => {
    this.props.firebase.fstor.collection('Orders').doc(orderId).update({state: 'pending cancel'});
  }

  _renderStatus = (state) => {
    console.log('state -> ', state);
    if (state === 'new' || state === 'pending new') {
      return (
        <div className="user-trade-history__status user-trade-history__status-green">
          Open
        </div>
      );
    }
    if (state === 'filled') {
      return (
        <div className="user-trade-history__status">
          Filled
        </div>
      );
    }
    if (state === 'cancelled') {
      return (
        <div className="user-trade-history__status user-trade-history__status-red">
            Cancelled
        </div>
      )
    }

    return (
      <div className="user-trade-history__status user-trade-history__status-red">
        None
      </div>
    );
  }

  _renderAction = (side) => {
    return side === 'bid' ? (
      <td className="user-trade-history__action user-trade-history__action-buy">
        Buy
      </td>
    ) : (side === 'ask' ? (
      <td className="user-trade-history__action user-trade-history__action-sell">
        Sell
      </td>
    ) : (
      <td>
        Other
      </td>
    ));
  }

  _renderTableRow = (order) => {
    const timeStr = order.created ? order.created.toDate().toString() : null;
    const fillPrice = order.fillPrice;
    const utcCreatedTime =timeStr ? moment(timeStr).utc().format('YYYY-MM-DD HH:mm:ss UTC') : null;
    const rowClass = classnames({
      'row--disabled': order.state === 'filled' || order.state === 'cancelled',
    });
    return (
      <tr className={rowClass}>
        <td>
          {this._renderStatus(order.state)}
        </td>
        <td>
          {utcCreatedTime}
        </td>
        <td style={{textTransform: 'capitalize'}}>
          {order.description}
        </td>
        <td>
          {getFormattedMonthByExpiry(order.expiry) /*get(getInfoByMaturity(order.month), 'formattedmonth', order.month)*/}
        </td>
        <td>
          {order.expiry}
        </td>
        {this._renderAction(order.side)}
        <td>
          {order.type}
        </td>
        <td>
          {parseFloat(order.price).toFixed(2)}
        </td>
        <td>
          {order.quantity}
        </td>
        <td>
          {fillPrice ? (`${fillPrice}`.indexOf('.') ? fillPrice : `${fillPrice}.00`) : ''}
        </td>
        <td>
          {order.state === 'new' || order.state === 'pending new' ? (
            <>
              <button onClick={this.cancelOrder(order.id)}>Cancel</button>
              <button style={{marginLeft: '4px'}}>Update</button>
            </>
          ) : null}
        </td>
      </tr>
    );
  }

  _renderTableRows = () => {
    return map(this.state.userOrders, this._renderTableRow);
  }

  render() {
    return (
      <Flex direction="vertical" className="user-trade-history">
        <p className="section-title">Order Blotter</p>
        { this.state.loading ? (
          <Loading />
        ) : (
          <Flex size="1" className="user-trade-history__table-container">
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Timestamp</th>
                  <th>Asset</th>
                  <th>Maturity</th>
                  <th>Expiry</th>
                  <th>Action</th>
                  <th>Type</th>
                  <th>Price</th>
                  <th>Quantity</th>
                  <th>Fill Price</th>
                  <th>Amend</th>
                </tr>
              </thead>
              <tbody>
                {this._renderTableRows()}
              </tbody>
            </Table>
          </Flex>
        )}
      </Flex>
    );
  }
};

UserTradeHistory.propTypes = {
  firebase: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
};

UserTradeHistory.defaultProps = {
};

export default withFirebase(UserTradeHistory);
