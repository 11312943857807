import React, {Component} from 'react';
import map from 'lodash/map';
import round from 'lodash/round';
import PropTypes from 'prop-types';
import {
  LineChart, Line, XAxis, YAxis, Tooltip,
} from 'recharts';

import Flex from 'components/ui/Flex';
import Loading from 'components/common/Loading';
import {withFirebase} from 'components/Firebase';


import './AssetChart.scss';

class AssetChart extends Component {
  constructor(props) {
    super(props);
    this.chartContainer = React.createRef();
    this.state = {
      chartWidth: 0,
      chartHeight: 0,
      data: [],
      loading: false,
    };
  }

  componentDidMount() {
    const containerDom = this.chartContainer.current;
    this.setState({
      chartWidth: containerDom && containerDom.clientWidth,
      chartHeight: containerDom && containerDom.clientHeight,
    });
    if(this.props.modelId!=0){
    this.refreshData();
    }
  }

  componentWillUnmount() {
    typeof this.unsubscribe === 'function' && this.unsubscribe();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.modelId !== this.props.modelId || prevProps.expiry !== this.props.expiry) {
      this.refreshData();
    }
  }

  refreshData() {
    this.setState({
      loading: true,
    });
    console.log('asset chart refreshdata expiry:' + this.props.expiry);
    // ToDo: Load the data for the curve.  ${this.props.modelId}//${this.props.expiry} should be used.
    // But we will leave it fix in case debugging is needed.  Data returned is same as DATA
    // Limit is artificial as well, there are more points than that.
    this.props.firebase.fstor.collection(`Synthetics-r2/${this.props.modelId}/${this.props.expiry}`)
        .orderBy('date', 'desc')
        .onSnapshot( (snapshot) => {
          const priceCurve = [];
          snapshot.forEach( (doc) => {
            const data = doc.data();
            if (!data.value || data.value == "0.00") {
              priceCurve.unshift({'date': data.date, 'value': null});
            } else {
              priceCurve.unshift({'date': data.date, 'value': data.value});
            }
          });
          this.setState({...this.transformChartData(priceCurve), 'loading': false});
        });
  }

  transformChartData(data) {
    let minValue = null;
    let maxValue = null;
    let firstTick = null;
    let lastTick = null;
    let stepSize = 10;
    let range = 100;
    const chartData = map(data, ({value, date}) => {
      console.log('value ->> ', value);
      if (value !== null) {
        const numValue = Number(value);
        if (minValue === null && maxValue === null) {
          minValue = numValue;
          maxValue = numValue;
        }
        if (numValue > maxValue) {
          maxValue = numValue;
        }
        if (numValue < minValue) {
          minValue = numValue;
        }
      }

      return {
        date,
        price: value,
      };
    });
    const valueRange = Math.ceil(maxValue - minValue);
    if (valueRange > range) {
      stepSize = round((valueRange / range) * stepSize);
      firstTick = round(minValue, -1);
      firstTick = firstTick < 0 ? 0 : firstTick;
      lastTick = round(maxValue, -1);
    } else {
      range = Math.ceil((1.4 * valueRange));
      stepSize = Math.ceil(range / 6);
      firstTick = Math.floor(minValue);
      firstTick = firstTick < 0 ? 0 : firstTick;
      lastTick = Math.ceil(maxValue);
    }
    return {
      data: chartData,
      stepSize,
      firstTick,
      lastTick,
      domainStart: firstTick - stepSize,
      domainEnd: lastTick + stepSize,
    };
  }

  getPriceTicks() {
    const ticks = [];
    const {firstTick, stepSize, domainStart, domainEnd} = this.state;
    const numOfTicks = Math.ceil((domainEnd - domainStart) / stepSize);
    const roundedFirstTick = Math.floor(firstTick, -1);

    for (let i = 0; i < numOfTicks; i += 1) {
      const tickValue = roundedFirstTick + (stepSize * i);
      ticks.push(`${tickValue}.00`);
    }
    return ticks;
  }

  render() {
    const {
      domainStart,
      domainEnd,
      chartWidth,
      chartHeight,
      stepSize,
      data,
      loading,
    } = this.state;
    return (
      <Flex direction="vertical" className="asset-chart">
        <p className="section-title" style={{backgroundColor: '#333', color: 'white'}}>Price Curve</p>
        <div style={{width: '100%', height: '100%'}} ref={this.chartContainer}>
          {(loading || !chartWidth) ? (
            <Loading />
          ) : (
            !data.length ? <p style={{textAlign: 'center'}}>No Data!</p> : (
              <LineChart
                width={chartWidth - 12}
                height={chartHeight - 24}
                data={data}
              >
                <XAxis dataKey="date"/>
                <YAxis ticks={this.getPriceTicks()} domain={[domainStart, domainEnd + stepSize]} tickFormatter={(tick) => tick} interval={0}/>
                <Tooltip />
                <Line type="monotone" dot={false} dataKey="price" stroke="#4682b4" />
              </LineChart>
            )
          )}
        </div>
      </Flex>
    );
  }
}

AssetChart.propTypes = {
  modelId: PropTypes.string.isRequired,
  expiry: PropTypes.string,
  firebase: PropTypes.object.isRequired,
};

export default withFirebase(AssetChart);
