/* eslint-disable max-len */
import React, {Component} from 'react';
import map from 'lodash/map';
import {Table} from 'react-bootstrap';
import PropTypes from 'prop-types';

import Flex from 'components/ui/Flex';
import Loading from 'components/common/Loading';
import {withFirebase} from 'components/Firebase';

import './MarketTrendTable.scss';

class MarketTrendTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fwdData: [],
      loading: false,
    };
  }

  componentDidMount() {
    if(this.props.modelId!=0){
    this.refreshData();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.modelId !== this.props.modelId) {
      this.refreshData();
    }
  }

  refreshData() {
    this.setState({loading: true});

    this.props.firebase.fstor.doc(`Synthetics-r2/${this.props.modelId}`)
        .get().then( (doc) => {
          if (doc.exists) {
            const expiries = doc.data().expiries;
            const data = []; // ToDo: Ugly code, replace it for some nice js.
            for (const key in expiries) {
              if ({}.hasOwnProperty.call(expiries, key)) {
                if(Date.parse(key)>=new Date()){
                const fields = expiries[key];
                data.push({expiry: key, current: fields.curr_value,
                  previous: fields.prev_value, change: fields.change});
                }
              }
            };
            data.sort((a, b) => Date.parse(a.expiry) - Date.parse(b.expiry) );
            this.setState({fwdData: data, loading: false});
          } else {
            console.log('Did not find document describing prices... ToDo');
          }
        }).catch(function(error) {
          console.log('Error getting document:', error);
        });
  }

  _renderTableRow = (act) => {
    return (
      <tr>
        <td>
          {act.expiry}
        </td>
        <td>
          {act.current}
        </td>
        <td>
          {act.previous}
        </td>
        {act.change >= 0 ? (
          <td className="market-trend-table__positive">
            {act.change}
          </td>
        ) : (
          <td className="market-trend-table__negative">
            {act.change}
          </td>
        )}
      </tr>
    );
  }

  _renderTableRows = () => {
    return map(this.state.fwdData, this._renderTableRow);
  }

  render() {
    return (
      <Flex className="market-trend-table" direction="vertical">
        <p className="section-title" style={{color: 'white', marginBottom: '4px', marginTop: '6px'}}>Forward Prices</p>
        { this.state.loading ? (
          <Loading variant="white" />
        ) : (
          <Flex size="1" className="market-trend-table__table-container">
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>Expiry</th>
                  <th>Current</th>
                  <th>Previous</th>
                  <th>Change</th>
                </tr>
              </thead>
              <tbody>
                {this._renderTableRows()}
              </tbody>
            </Table>
          </Flex>
        )}
      </Flex>
    );
  }
};

MarketTrendTable.propTypes = {
  modelId: PropTypes.string.isRequired,
  firebase: PropTypes.object.isRequired,
};

MarketTrendTable.defaultProps = {

};

export default withFirebase(MarketTrendTable);
