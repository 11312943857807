import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Flex from 'components/ui/Flex';

import AssetTradeForm from './components/AssetTradeForm';
import AssetChart from './components/AssetChart';
import AssetOrderBook from './components/AssetOrderBook';
import UserTradeHistory from './components/UserTradeHistory';
import MarketTrendTable from './components/MarketTrendTable';
import MarketChart from './components/MarketChart';

import './AssetTrade.scss';

export default class AssetTrade extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expiry: '',
    };
  } 


  render() {
    const {modelId, userId, expiry, onExpiryChange} = this.props;
    return (
      <div className="asset-trade-board">
        <Flex align="stretch" style={{width: '100%'}}>
          <Flex size="1" direction="vertical">
            <AssetChart modelId={modelId} userId={userId} expiry={expiry}/>
            <AssetTradeForm userId={userId} modelId={modelId} expiry={expiry} onExpiryChange={onExpiryChange} />
          </Flex>
          <AssetOrderBook modelId={modelId} expiry={expiry} />
          <Flex size="1" direction="vertical">
            <MarketChart modelId={modelId}/>
            <MarketTrendTable modelId={modelId}/>
          </Flex>
        </Flex>
        <UserTradeHistory userId={userId} />
      </div>
    );
  }
};

AssetTrade.propTypes = {
  modelId: PropTypes.string,
  userId: PropTypes.string,
  expiry: PropTypes.string,
  onExpiryChange: PropTypes.func,
};

AssetTrade.defaultProps = {

};
